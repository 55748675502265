  .minting {
    background-color: #333333;
    border-radius: 12px;
    text-align: center;
    margin:auto;
    width: 100%;
    min-width: 50px;
    max-width: 400px; 
    color:white;
  }

  .minting button{
    display: inline-block;
    background-color: #f48847;
    color:white;
    width: 100%;
    min-width: 50px;
    max-width: 125px; 
    font-size:16px;
    border-radius:12px;
    margin:2.5px;
    }
    .minting button:hover,
    .minting button:active{
      background-color: #894b24;
      border-color: #000000;
    }

  .minting p {
    font-size: 18px;
  }
  
  .minting button {
    justify-self:center;
    text-align: center; 
    white-space: pre-line;
  } 

  .minting button {
    margin-top: 10px;
  }

  /* button {
    display: inline;
  }

  .buttons {
    display:flex;
 } */