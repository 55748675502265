.navBar {
    background-color: #333333;
    border-radius: 12px;
    text-align: center;
    margin:auto;
    width: 100%;
    min-width: 50px;
    max-width: 400px; 
    color:white;
}

.navBar #consensys {
    background-color: aqua;
    color:black;
    text-align: center;
    margin:auto;
    margin-top: 1rem; 
}

.navBar button{
    display: inline-block;
    background-color: #f48847;
    color:white;
    width: 100%;
    min-width: 50px;
    max-width: 125px; 
    font-size:16px;
    border-radius:12px;
    margin:2.5px;
    }
    .navBar button:hover,
    .navBar button:active{
      background-color: #894b24;
      border-color: #000000;
    }