.generalContractInfo {
  background-color: #333333;
  border-radius: 12px;
  
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 700px; 
  color:white;
  }

  .generalContractInfo #wrap {
    overflow-wrap: break-word;
  }