.minting p {
    font-size: 18px;
  }
  
  .minting button {
    justify-self:center;
    text-align: center; 
    white-space: pre-line;
  } 

  .minting button {
    margin-top: 10px;
  }

  /* button {
    display: inline;
  }

  .buttons {
    display:flex;
 } */