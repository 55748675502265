.logger {
  background-color: #333333;
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 450px; 
  border-radius: 12px;
}

.logger p {
  text-align: center;
  color:#618685;
}