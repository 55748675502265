.centeredCard {
    background-color: #333333;
    border-radius: 12px;
    margin:auto;
    margin-top: 1rem; 
  }

.centeredCard h2 {
  color:#ffffff;
}

.centeredCard p {
  color:#ffffff;
}