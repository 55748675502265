.grantMinterRole {
  background-color: #333333;
  border-radius: 12px;
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 400px; 
  color:white;
}

.grantMinterRole button{
  display: inline-block;
  background-color: #f48847;
  color:white;
  width: 100%;
  min-width: 50px;
  max-width: 125px; 
  font-size:16px;
  border-radius:12px;
  margin:2.5px;
  }
  .grantMinterRole button:hover,
  .grantMinterRole button:active{
    background-color: #894b24;
    border-color: #000000;
  }

  .grantMinterRole p {
    font-size: 18px;
  }

  .grantMinterRole button {
    justify-self:center;
    text-align: center; 
    white-space: pre-line;
  } 

  .grantMinterRole button {
    margin-top: 10px;
  }

  .grantMinterRole #aye {
    margin-top: 10px;
  }